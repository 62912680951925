.graphsme{
    padding: .5rem;
    margin: 0rem;
    background-color: #eee;
    border-radius: 12px;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.graphsme .graphsme01 p:nth-child(1){
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.graphsme .graphsme01 h5{
    color: #600160;
    padding: 0rem 1rem;
}

.graphsme .graphsme01 .MuiSvgIcon-root{
  width: 45px;
  height: 40px;
}


.graphsme .graphsme01 .graphsme02{
   padding: 0px;
   margin: 0px;
   display: flex;
   flex-direction: column;
   gap: 0.5rem;
   margin-top: 0rem;
}

.graphsme .graphsme01 .graphsme02 p{
  padding: 1rem;
  width: 100%;
  display:inline-flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(1){
  background-color: #5d265d;
  border-radius: 0.4rem;
  font-size: 1.5em;
  margin: 0;
  color: white;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(2){
  background-color: #ed69ed;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: white;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(3){
  background-color: white;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: black;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(4){
  background-color: #1e1818;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: white;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(5){
  background-color: rgb(236, 102, 102);
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: rgb(255, 255, 255);
}

.graphsme .graphsme01 .graphsme02 p:nth-child(6){
  background-color: #7b357b;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: white;
}

.graphsme .graphsme01 .graphsme02 p:nth-child(7){
  background-color: #ffffff;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: rgb(0, 0, 0);
}

.graphsme .graphsme01 .graphsme02 p:nth-child(8){
  background-color: #e682e6;
  border-radius: 0.4rem;
  font-size: 1.5em;
  color: white;
}
